import { Dialog } from 'vant'
import {
  getBcompanyPermission,
  getBcompanyPermissionSubject,
} from '@/service/common'
import store from '@/store/'
import router from '@/router'

export const TOKEN = 'token'

export const permissionEnum = {
  login: '账号被限制登录，如有疑问请联系客服处理',
  order: '抱歉、你的下单权限已被冻结，无法代发订单，如有疑问请联系客服',
  recharge:
    '你的充值权限被冻结，无法进行充值，如有疑问，请联系你的上级或客服处理',
  rechargeSuperior:
    '你的上级充值权限被冻结，无法进行充值，如有疑问，请联系你的上级或客服处理',
  upDownSuperior:
    '你的上级充值权限被冻结，无法进行上账/下账，如有疑问，请联系你的上级或客服处理',
  upDownSuperiorSub:
    '由于你的上级充值权限被冻结，无法给下级上账/下账，如有疑问，请联系你的上级或客服处理',
  upDownBill:
    '由于你的充值权限被冻结，无法给下级上账/下账，如有疑问，请联系你的上级或客服处理',
  upDownAudit:
    '由于你的充值权限被冻结，无法审核下级的上/下账，如有疑问，请联系你的上级或客服处理',
  upDownSelf:
    '你的上下账权限被冻结，无法申请，如有疑问请联系你的上级或客服处理',
  upDownOthers: '该股东上下账权限被冻结，如有疑问请联系客服',
  cashOut: '你的账号已冻结提现权限，如有疑问请联系客服处理',
  inviteSelf: '你的账号已被冻结，无法邀请客户加入，如有疑问请联系客服',
}

export const getPermissionRes = (key, msg = '') => {
  Dialog.alert({
    title: '提示',
    message: msg || permissionEnum[key],
  })
  return false
}

export const refreshPermission = async () => {
  const customerInfo = store.getters.customerInfo
  const currentSelectRole = store.getters.currentSelectRole
  const res = await getBcompanyPermission({
    custCode: customerInfo.custCode,
  })
  const obj = {
    ...currentSelectRole,
    permission: res.respData.find(
      (item) => item.subjectCode === currentSelectRole.creditCard
    ),
  }
  if (obj.permission?.freezeLogin) {
    return Dialog.alert({
      title: '提示',
      message: '账号被限制登录，如有疑问请联系客服处理',
    }).then(() => {
      router.replace('/select-mode')
    })
  }
  const parentRes = await getBcompanyPermissionSubject({
    custCode: currentSelectRole.parentCustCode,
    subjectCode: currentSelectRole.parentCreditCard,
  })
  obj.parentPermission = parentRes.respData
  store.commit('SET_CURRENT_SELECT_ROLE', obj)
}

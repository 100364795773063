import { get, post } from '@/service/request'

//h5首页搜索系列
export const getSeries = () => get(`goods/h5/goods-series/list`)
//获取一级分类列表
export const getCategory = () => get(`/goods/h5/category/top`)

export const getCategoryWithCloud = () => get(`/goods/h5/category/topWithCloud`)

export const getCategoryNewMode = () =>
  get(`/goods/h5/goods/front/category/parent/list`)
//获取子级分类
export const getSecondSeries = (parentCode) =>
  get(`/goods/h5/category/sub/${parentCode}`)

export const getSecondSeriesNewMode = (pid) =>
  get(`/goods/h5/goods/front/category/selectByPid/${pid}`)

//搜索商品
export const getGoodsList = (objQuery, page, size) =>
  post('/goods/h5/goods/search', {
    objQuery,
    page,
    size,
  })

/**
 * 获取城主地区
 */
export const getLordRegionByCode = (customerCode) =>
  get(`/customer/h5/invite/getLordRegion/${customerCode}`)

/**
 * 查询客户列表
 */
export const getCloudCustomerList = (params) =>
  get('/customer/h5/invite/list', params, {
    headers: { ignoreSaleChannel: true },
  })

/**
 * 创建二维码
 */
export const createQrCode = (data) => post('/customer/h5/invite', data)

/**
 * H5客户主体下各钱包的本人可用余额
 * @param customCode
 * @param saleChannel
 * @param yhno
 * @param customSubjectCode
 */
export const getWalletBySySubject = (
  customCode,
  saleChannel,
  yhno,
  customSubjectCode
) =>
  get(
    `/dcw/h5/customWallet/wallet/${customCode}/${saleChannel}/${yhno}/${customSubjectCode}`
  )

/**
 * H5客户主体下各钱包的本人可用余额
 * @param customCode
 * @param saleChannel
 * @param yhno
 * @param customSubjectCode
 */
export const getWalletBySySubjectNewMode = (
  customCode,
  saleChannel,
  yhno,
  customSubjectCode
) =>
  get(
    `/dcw/bcompany/h5/customWallet/wallet/${customCode}/${saleChannel}/${yhno}/${customSubjectCode}`
  )

/**
 * h5首页查询订单相关的数量
 */
export const queryOrderQuantityInfo = (data) =>
  post('/trading/h5/order/queryOrderQuantityInfo', data)

// 云店上下账申请待审核数量
export function getCounts(customCode, yhno) {
  return get(
    `/dcw/h5/custom/apply/up/bill/getCounts/${customCode}/${yhno}`,
    {},
    {}
  )
}
